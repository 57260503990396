$margin-sizes: (
  zero: 0,
  nano: 5,
  micro: 10,
  xxs: 15,
  xs: 30,
  sm: 45,
  md: 60,
);
$directions: 'top', 'right', 'bottom', 'left';

@media only screen and (max-width: 800px) {
  .hideMd {
    display: none !important;
  }
}

@media only screen and (max-width: 576px) {
  .hideSm {
    display: none !important;
  }
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.fxRow {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-content: stretch;
  gap: 10px;
}

.fxColumn {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-content: stretch;
  gap: 10px;
}

.fxFlex {
  flex: 1;
}

.fxJustifyCenter {
  justify-content: center;
}
