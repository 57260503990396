* {
  //scrollbar-color: var(--grey-6) transparent;
  scrollbar-width: thin;
}

.border,
.bordered {
  border: 1px solid var(--clr-border);
}

.overflow-auto {
  overflow: auto;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.color-primary {
  color: var(--clr-primary);
}

.color-secondary {
  color: var(--clr-2);
}

.color-muted {
  color: var(--clr-grey-7);
}

.type-cell {
  background: var(--clr-primary-10);
  color: var(--clr-primary);
}

.row-number-cell {
  font-size: 0.8em;
  color: var(--clr-secondary);
}

.table-icon {
  font-size: 1.2em;
  background-color: var(--clr-info-10);
  border: 1px solid var(--clr-info-20);
  padding: 3px;
  border-radius: 50%;
  color: var(--clr-primary);
  width: 36px;
  height: 36px;
  line-height: 30px;
  text-align: center;
}

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  .table-icon {
    background-color: var(--clr-grey-1);
    border: 1px solid var(--clr-info-100);
  }
}

.dialog-no-padding .ant-modal-body {
  padding: 0;
}

.dialog-no-padding .ant-modal {
  top: 50px;
}
