:root {
  --clr-border: #e0e0e0;
  --border-radius: 5px;
}

.ant-card-head {
  min-height: 38px;
}

.ant-card-extra {
  padding: 3px 0;
}

.inner-zero .ant-card-body {
  padding: 0;
}

.ant-form-item {
  margin-bottom: 10px;
}

.ant-badge-multiple-words {
  padding: 0 4px;
}

.ant-btn-round.ant-btn-lg {
  font-size: 14px;
}

.ant-layout {
  background: var(--clr-grey-2);
}

.dynamic-tabs {
  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav {
    margin: 0;
  }

  .ant-tabs-content {
    height: 100%;
    min-height: 100%;
    background: var(--clr-grey-1);
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
  }

  .ant-tabs-tabpane {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
  }

  .ant-card-body {
    flex: 1;
  }
}

.ant-card-head-title {
  padding: 12px 0px;
  font-size: 14px;
  color: var(--clr-1);
}

.ant-table table {
  width: 100%;
}

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
}

.ant-table-body {
  overflow-y: auto !important;
}

.ant-collapse-borderless {
  background-color: vas(--clr-grey-1);
}

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: var(--clr-info-10);
}

.ant-table-thead > tr > th {
  background: var(--clr-grey-1);
  font-weight: 400;
  color: var(--clr-grey-8);
  //padding: 0 10px;
  font-size: 0.9em;
}

.ant-table-thead th.ant-table-column-sort {
  background: var(--clr-grey-1);
}

.tab-h-100 {
  height: calc(100vh - 200px);
}

.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-node-content-wrapper {
  flex: none;
}

.ant-input-affix-wrapper,
.ant-input,
.ant-collapse {
  border-radius: var(--border-radius);
  overflow: hidden;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: var(--border-radius) var(--border-radius) 0 0;
}

.ant-select-show-search.ant-select.rounded:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 30px;
  height: 32px;
  line-height: 32px;
}

.ant-select-multiple .ant-select-selection-item {
  border-color: var(--clr-primary-40);
  background: var(--clr-primary-10);
  //border-radius: 20px;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item {
  left: 0;
  padding: 0 calc(50% - 20px / 2);
  text-overflow: clip;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon {
  margin: 0;
  font-size: 20px;
  line-height: 40px;
}

.ant-layout-sider,
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: var(--clr-sidebar-bg);
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: var(--clr-sidebar-bg);
}

nz-sider.ant-layout-sider-collapsed {
  flex: 0 0 var(--sidebar-collapse-width) !important;
  max-width: var(--sidebar-collapse-width) !important;
  min-width: var(--sidebar-collapse-width) !important;
  width: var(--sidebar-collapse-width) !important;
}

.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-treenode.dragging::after {
  border: none;
}

.ant-tabs-tab .anticon {
  margin-right: 5px;
}

.ant-tabs-top > .ant-tabs-content-holder {
  border-left: 1px solid var(--clr-border);
  border-right: 1px solid var(--clr-border);
  border-bottom: 1px solid var(--clr-border);
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  border-color: var(--clr-border);
}

.ant-tabs-content-holder {
  overflow: auto;
}

.nz-resizable .nz-resizable-handle-right {
  .resize-handler-wrap {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon {
    background: var(--clr-grey-1);
    border: 1px solid #ddd;
    text-align: center;
    font-size: 12px;
    height: 20px;
    line-height: 20px;
  }
}

.nz-resizable .nz-resizable-handle-left {
  .resize-handler-wrap {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon {
    background: var(--clr-grey-1);
    border: 1px solid #ddd;
    text-align: center;
    font-size: 12px;
    height: 20px;
    line-height: 20px;
  }
}

.ant-divider-horizontal.ant-divider-with-text {
  margin: 10px 0;
  font-size: 12px;
}

.ant-divider-inner-text {
  padding: 0;
}

.ant-card-bordered {
  border: 1px solid var(--clr-border);
}

.rounded {
  &.ant-btn-group > .ant-btn:first-child:not(:last-child),
  &.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  &.ant-btn-group > .ant-btn:last-child:not(:first-child),
  &.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 20px;
    height: 34px;
  }
}
