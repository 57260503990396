:root {
  --clr-1: #393e46;
  --clr-2: #344955;
  --clr-3: #ae445a;
  --clr-4: #198d8f;

  --clr-primary: var(--clr-4);
  --clr-primary-10: #eef0f8;
  --clr-primary-20: #dde1f1;
  --clr-primary-40: #bbc4e4;
  --clr-primary-60: #9aa6d6;
  --clr-primary-80: #7889c9;

  --clr-secondary: var(--clr-3);
  --clr-secondary-10: #edf0f1;
  --clr-secondary-20: #dce2e4;
  --clr-secondary-40: #b9c6ca;
  --clr-secondary-60: #b9c6ca;
  --clr-secondary-80: #728e95;

  --clr-grey-1: #fff;
  --clr-grey-2: #f5f6f8;
  --clr-grey-3: #edeff5;
  --clr-grey-4: #dce0ed;
  --clr-grey-5: #cacfd9;
  --clr-grey-6: #a3aabc;
  --clr-grey-7: #65798f;
  --clr-grey-8: #393f4d;

  --clr-border: #e0e0e0;
  --clr-sidebar-bg: #31363f;
  --clr-badge: #ff6500;

  --clr-info-10: #ebf4f4;
  --clr-info-20: #cce3e3;
  --clr-info-40: #99c2ff;
  --clr-info-60: #66a3ff;
  --clr-info-80: #3385ff;
  --clr-info-100: #0064ff;
  --clr-info-highlight: #0061e6;

  --clr-error-10: #fae6ea;
  --clr-error-20: #f5ced5;
  --clr-error-40: #ec9dab;
  --clr-error-60: #e26c80;
  --clr-error-80: #d93b56;
  --clr-error-100: #cf0a2c;
  --clr-error-highlight: #82061b;

  --clr-warning-10: #fdf4ea;
  --clr-warning-20: #fce9d5;
  --clr-warning-40: #f9d3ac;
  --clr-warning-60: #f5bd82;
  --clr-warning-80: #f2a759;
  --clr-warning-100: #ef912f;
  --clr-warning-highlight: #b06a22;

  --clr-success-10: #eafbf4;
  --clr-success-20: #d5f7ea;
  --clr-success-40: #abefd5;
  --clr-success-60: #80e6c0;
  --clr-success-80: #56deab;
  --clr-success-100: #2cd696;
  --clr-success-highlight: #22a876;

  --clr-filter: var(--grey-2);
  --clr-factor: #e4e0f0;
  --clr-response: #ede7d0;

  --header-height: 48px;
  --sidebar-collapse-width: 60px;

  --breakSm: 576px;
}
