revo-grid[theme='default'] revogr-header,
revo-grid:not([theme]) revogr-header,
revo-grid[theme='default'] .rowHeaders,
revo-grid:not([theme]) .rowHeaders {
  background-color: var(--clr-grey-2);
}

revo-grid[theme='default'],
revo-grid:not([theme]) {
  border: 1px solid var(--clr-border);
}

revo-grid[theme='default'] revogr-data .rgRow,
revo-grid:not([theme]) revogr-data .rgRow {
  line-height: 32px !important;
}

revo-grid[theme='default'] revogr-data .rgRow.focused-rgRow,
revo-grid:not([theme]) revogr-data .rgRow.focused-rgRow {
  background-color: var(--clr-info-10) !important;
}

revogr-edit input {
  padding: 0 10px;
  font-size: 14px;
}

.rgHeaderCell .rv-filter {
  opacity: 0.3 !important;
  margin-top: 2px;
  margin-right: 2px;

  &.active {
    background: var(--clr-warning-80);
    border-radius: 50%;
    opacity: 1 !important;

    .filter-img {
      opacity: 1 !important;
      color: #fff;
    }
  }
}
